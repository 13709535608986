import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getSizeList } from "../../API/Reducer/sizeReducer";
import { useDispatch, useSelector } from "react-redux";
import { toggleSizeCheckList } from "../../API/Reducer/adminProductReducer";

const SizeList = ({searchSizeStr}) => {
  const [orgData, setOrgData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { sizeList, sizePageData, sizeData } = useSelector((state) => state.size);
  const { sizeCheckData } = useSelector((state) => state.adminproduct);

  const loadInitialData = async (data = { page: 1, limit: 100 }) => {
    setLoading(true);
    await dispatch(getSizeList(data));
    setLoading(false);
  };
  useEffect(() => {
    loadInitialData();
  }, [dispatch]);

  useEffect(() => {
    if (sizeList?.length > 0) {
      setOrgData(sizeList);
    }
  }, [sizeList]);

  const searchInArray = (key, searchValue = searchSizeStr, array = sizeList) => {
    const filteredData = array.filter((item) => {
      if (Array.isArray(key)) {
        // For multiple keys
        return key.some((k) =>
          item[k]?.toString().toLowerCase().includes(searchValue.toLowerCase())
        );
      } else {
        // For a single key
        return item[key]
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      }
    });
    setOrgData(filteredData);
    if (filteredData.length <= 0) {
      loadInitialData({ page: 1, limit: 100, sizename: searchValue });
    }
  };
  useEffect(() => {
    searchInArray("sizename");
  }, [searchSizeStr]);

  const handleCheckboxChange = (event, sizeId) => {
    dispatch(toggleSizeCheckList(sizeId));
  };
   return (
    <>
      <ul className="horizontal-list">
        {orgData?.map((element, index) => {
          return (
            <li key={index} style={{width:"150px",whiteSpace: "normal", wordWrap: "break-word"}}>
              <FormControlLabel
                control={<Checkbox name="gilad" color="success"
                onChange={(event) => handleCheckboxChange(event, element?.id)}
                checked={sizeCheckData.includes(element?.id)}
                />}
                label={element?.sizename}                
              />
            </li>
          );
        })}        
      </ul>
    </>
  );
};

export default SizeList;
