import { createSlice } from "@reduxjs/toolkit"; 
import { setConfirmDialogData } from "./confirmDialogReducer";
import { callAjax, formatDatetime_DMY, formatDatetime_YMD, getImageUrl } from "../../MIS/Global";
import { reloadPage, setAlertData } from "./alertReducer";
import { errorClear, errorSet } from "./errorReducer";
   

const userReducer = createSlice({
    name: 'user',
    initialState: {
        user:{},   
        userData:[],     
    },

    reducers: {           
         setUser(state, action) {
            state.user = {
              ...state.user,
              [action.payload.name]: action.payload.value,
            };
        },
        setUserData(state,action){
          state.userData=action.payload;
        },
                     
    }
});

export const { setUser,setUserData} = userReducer.actions;
export default userReducer.reducer;
 
export const handleUserChange = (e, name) => {
    return function actionData(dispatch) {
      if (e && e.target) {
        dispatch(setUser({ name: e.target.name, value: e.target.value }));
      }
    };
  };

  export const otpEmail = (data,otp,setActiveStep) => {
    let isError=0;    
    return async function actionData(dispatch) {
      data={email:data?.uemail,title:"OTP",subject:"D2 Super",message:otp,
      imgurl:getImageUrl("public/d2/d2logo.png"),umob:data?.umob
    };      
       let res=await callAjax("otpemail",data);
       if(res?.data>0){       
       }else if(res?.data<=-1){
        dispatch(
          setAlertData({
            type: "error",
            msg: `The user already exists with the phone number ${data?.umob}.`,
            code: 100,
          })
        );
        setActiveStep(1)
      }
       else{
        dispatch(
          setAlertData({
            type: "error",
            msg: "Something went wrong!Please try again",
            code: 100,
          })
        );
        setActiveStep(1)
       }      
    };
}

export const forgotOtpEmail = (data,otp,setActiveStep,setValue) => {
  let isError=0;    
  return async function actionData(dispatch) {
    data={title:"OTP",subject:"D2 Super",message:otp,
    imgurl:getImageUrl("public/d2/d2logo.png"),umob:data?.umob
  };      
     let res=await callAjax("forgototp",data);
     if(res?.data>0){       
      setValue('id',res?.data);
     }else if(res?.data<=-1){
      dispatch(
        setAlertData({
          type: "error",
          msg: `This mobile number is not registered.`,
          code: 100,
        })
      );
      setActiveStep(0)
    }
     else{
      dispatch(
        setAlertData({
          type: "error",
          msg: "Something went wrong!Please try again",
          code: 100,
        })
      );
      setActiveStep(0)
     }      
  };
}


export const saveUser = (data) => {
    let isError=0;    
    return async function actionData(dispatch) {
      data={...data,cmpmob:data?.umob};      
       let url="signup";
       if(data?.id>0){
        url="userupdate";
       }
       let res=await callAjax(url,data);
       if(res?.data>0){   
        if(url==="userupdate"){
          dispatch(
            setAlertData({
              type: "success",
              msg: `Your account has been updated successfully`,
              code: 200,
            })         
          );
        }else{
        dispatch(
          setAlertData({
            type: "success",
            msg: `Great! Your account has been created successfully. Let's get started!`,
            code: 200,
          })         
        );
        }
        reloadPage(500,"/login");
      }else if(res?.data<=-1){
        dispatch(
          setAlertData({
            type: "error",
            msg: `User already exists.`,
            code: 100,
          })
        );
      }
      else{
       dispatch(
         setAlertData({
           type: "error",
           msg: "Something went wrong!Please try again",
           code: 100,
         })
       );
      } 

    };
}

export const signUser = (data) => {
  let isError=0;    
  return async function actionData(dispatch) {
     let res=await callAjax("signin",data);
     if(res?.data?.id>0){   
      dispatch(
        setAlertData({
          type: "success",
          msg: `Sign in successfully.`,
          code: 200,
        })
      );
       
      reloadPage(500,"/home");
    }else if(res?.data<=-1){
      dispatch(
        setAlertData({
          type: "error",
          msg: `Invalid Credentials (Mobile Number & Password).`,
          code: 100,
        })
      );
    }
    else{
     dispatch(
       setAlertData({
         type: "error",
         msg: "Something went wrong!Please try again",
         code: 100,
       })
     );
    } 

  };
}

export const getUsers = (data) => {
  let isError=0;    
  return async function actionData(dispatch) {
     let res=await callAjax("getusers",data);
     if(res?.data?.length>0){   
      dispatch(setUserData(res?.data));
    }else{dispatch(setUserData([]));}     

  };
}