import { useMemo } from "react";
import { handleUserGetLocalStorage } from "../../MIS/Global";
 
// Hook for global form configuration
export const useSubProductConfig = (customValues = {}, customValidation = {}) => {  
  const defaultValues = useMemo(() => ({
    id: null,
    mpid:"0",
    subpname: "",
    imgpath: "",
    subp_descp:"-",
    subp_status: 0,
    loginid: handleUserGetLocalStorage("loginid"),
    ...customValues, // Allows custom default values passed from the component
  }), [customValues]);

  // Validation schema
  const validationSchema = useMemo(() => ({
    subpname: {
      required: "Sub-Product name is required",     
      // ...customValidation.subpname, // Override validation if provided
    },
    // You can add more fields here
  }), [customValidation]);

  return { defaultValues, validationSchema };
};
