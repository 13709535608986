import React, { useEffect, useState } from 'react';

const LocalIP = () => {
  const [localIps, setLocalIps] = useState([]);

  useEffect(() => {
    const getLocalIPs = () => {
      const RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
      
      if (!RTCPeerConnection) {
        console.log('WebRTC is not supported by this browser.');
        return;
      }

      const pc = new RTCPeerConnection({ iceServers: [] });
      pc.createDataChannel(''); // Create a data channel
      const ipSet = new Set(); // To store unique IP addresses

      // Create offer and set local description
      pc.createOffer()
        .then(offer => pc.setLocalDescription(offer))
        .catch(error => console.error(error));

      // Listen for ICE candidates
      pc.onicecandidate = (ice) => {
        if (ice && ice.candidate && ice.candidate.candidate) {
          const candidateParts = ice.candidate.candidate.split(' ');
          const candidateType = candidateParts[7]; // This part describes the type (host, srflx, etc.)
          
          if (candidateType === 'host') { // Filter out non-local IPs
            const localIP = candidateParts[4]; // Extract the local IP
            if (!ipSet.has(localIP)) { // Prevent duplicates
              ipSet.add(localIP);
              setLocalIps(prevIps => [...prevIps, localIP]);
            }
          }
        }
      };

      pc.onicegatheringstatechange = () => {
        if (pc.iceGatheringState === 'complete') {
          pc.close(); // Close the connection once gathering is complete
        }
      };
    };

    getLocalIPs();
  }, []);

  const [ip1, setIp1] = useState('');

  useEffect(() => {
      fetch('https://jcbk.teeblanka.com/api/local-ip/')
        .then(response => response.json())
        .then(data => setIp1(data.ip))
        .catch(error => console.error('Error fetching IP:', error));
  }, []);

  const [ip2, setIp2] = useState('');
  useEffect(() => {
      fetch('https://jcbk.teeblanka.com/api/local-ip1/')
        .then(response => response.json())
        .then(data => setIp2(data.ip))
        .catch(error => console.error('Error fetching IP:', error));
  }, []);

  const [ip3, setIp3] = useState('');
  useEffect(() => {
    const getPublicIP = async () => {
      try {
        const response = await fetch('https://api64.ipify.org?format=json');
        const data = await response.json();
        setIp3(data.ip);
      } catch (error) {
        console.error('Error fetching public IP:', error);
      }
    };
  
    getPublicIP();
  }, []);

  return (
    <div>
      <h1>Your Local IP Address(es):</h1>
      {localIps.length > 0 ? (
        <ul>
          {localIps.map((ip, index) => (
            <li key={index}>{ip} - {index}</li>
          ))}
        </ul>
      ) : (
        <p>Fetching local IP address...</p>
      )}
      <h1>Second IP Address is: {ip1}</h1>
      <h1>Third IP Address is: {ip2}</h1>
      <h1>Public IP Address is: {ip3}</h1>
    </div>
  );
};

export default LocalIP;
