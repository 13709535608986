import React, { useEffect, useState } from 'react';

const NotificationComponent = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const uid = 2;  // Replace this with the actual user ID logic

    if (!uid) return;
    // Create the WebSocket connection
    const socket = new WebSocket(`ws://127.0.0.1:8000/ws/notify/${uid}/`);
    
    // Triggered when a message is received from the server
    socket.onmessage = (event) => { 
      console.log(event.data);  // Debug the event data
      try {
        const data = JSON.parse(event.data);  // Parse the JSON data received
        
        // Check if data is an object or an array
        if (Array.isArray(data)) {
          // If the data is an array, append it directly
          setNotifications((prevNotifications) => [...prevNotifications, ...data]);
        } else {
          // If the data is an object, append it as a single item
          setNotifications((prevNotifications) => [...prevNotifications, data]);
        }
      } catch (err) {
        console.error("Failed to parse WebSocket message", err);
      }
    };

    // Error handling for WebSocket
    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      socket.close();  // Close the connection on error
    };

    // Cleanup function to close the WebSocket connection when the component unmounts
    return () => {
      socket.close();
    };
  }, []);
  

  return (
    <div>
      <h2>Notifications</h2>
      <ul>
        {notifications.map((notification, index) => (
          <li key={index}>{notification?.msg}-{notification?.id}</li>
        ))}
      </ul>
    </div>
  );
};

export default NotificationComponent;
