import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Grid from "@mui/material/Grid";
import "./adminproduct.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { handleFabricModal } from "../../API/Reducer/adminProductReducer";

import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  CircularProgress,
} from "@mui/material";
import { handleMaxLengthInput } from "../../MIS/Global";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { useGlobalForm } from "../../API/Hooks/useGlobalForm";
import { useFabricConfig } from "../../API/Hooks/useFabricMaster";
import AlertComp from "../AlertMessageCom/AlertComp";
import {
  deleteFabric,
  editFabric,
  getFabricList,
  saveFabric,
  setFabricData,
} from "../../API/Reducer/fabricReducer";

const FabricMaster = () => {
  const [searchStr, setSearchStr] = useState("");
  const [orgData, setOrgData] = useState([]);
  const [loading, setLoading] = useState(false);
  const observerRef = useRef(null);
  const tableRef = useRef(null);
  const dispatch = useDispatch();

  const { defaultValues, validationSchema } = useFabricConfig();
  const { fabricList, fabricPageData, fabricData } = useSelector(
    (state) => state.fabric
  );
  const { instanceIsLoading } = useSelector((state) => state.menu);

  const onSubmit = async (data) => {
    dispatch(saveFabric(data, reset));
  };

  const onError = (errors) => {};
  const { register, handleSubmit, errors, reset, setValue, getValues } =
    useGlobalForm(defaultValues, validationSchema, onSubmit, onError);

  const loadInitialData = async (data = { page: 1, limit: 100 }) => {
    setLoading(true);
    await dispatch(getFabricList(data));
    setLoading(false);
  };
  useEffect(() => {
    loadInitialData();
  }, [dispatch]);

  const loadMoreData = useCallback(async () => {
    if (!loading && fabricPageData?.currentPage < fabricPageData?.totalPages) {
      setLoading(true);
      const newPage = fabricPageData?.currentPage + 1;
      await dispatch(getFabricList({ page: newPage, limit: 100 }));
      setLoading(false);
    }
  }, [
    fabricPageData?.currentPage,
    fabricPageData?.totalPages,
    loading,
    dispatch,
  ]);

  const observeBottom = useCallback(
    (entries) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting) {
        loadMoreData(); // Trigger loading more data when the bottom is reached
      }
    },
    [loadMoreData]
  );

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect(); // Disconnect any existing observer
    if (tableRef.current) {
      const bottomElement = tableRef.current.querySelector(
        "tbody > tr:last-child"
      ); // Get the bottom row
      if (bottomElement) {
        observerRef.current = new IntersectionObserver(observeBottom);
        observerRef.current.observe(bottomElement); // Observe bottom row
      }
    }

    return () => {
      if (observerRef.current) observerRef.current.disconnect(); // Cleanup observer on unmount
    };
  }, [orgData, observeBottom]);

  useEffect(() => {
    if (fabricList?.length > 0) {
      setOrgData(fabricList);
    }
  }, [fabricList]);

  const searchInArray = (key, searchValue = searchStr, array = fabricList) => {
    const filteredData = array.filter((item) => {
      if (Array.isArray(key)) {
        // For multiple keys
        return key.some((k) =>
          item[k]?.toString().toLowerCase().includes(searchValue.toLowerCase())
        );
      } else {
        // For a single key
        return item[key]
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      }
    });
    setOrgData(filteredData);
    if (filteredData.length <= 0) {
      loadInitialData({ page: 1, limit: 100, fabricname: searchStr });
    }
  };
  useEffect(() => {
    searchInArray("fabricname");
  }, [searchStr]);
  useEffect(() => {
    if (Object.keys(fabricData).length > 0) dispatch(editFabric(setValue));
  }, [fabricData]);

  const memoizedRows = useMemo(() => {
    return orgData.map((element, index) => {
      let bgcolor = "#fff";
      if (index % 2 === 0) {
        bgcolor = "aliceblue";
      }
      return (
        <TableRow key={index}>
          <TableCell>{element?.fabricname}</TableCell>
          <TableCell sx={{ cursor: "pointer" }}>
            <EditIcon
              onClick={(e) => {
                dispatch(setFabricData(element));
              }}
            />
            <DeleteIcon
              onClick={(e) => {
                dispatch(deleteFabric({ id: element?.id }));
              }}
            />
          </TableCell>
        </TableRow>
      );
    });
  }, [orgData, dispatch]);
  return (
    <>
      {/* <AlertComp/> */}
      <form onSubmit={handleSubmit} autoComplete="off">
        <Grid
          container
          sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <Grid item md={12} xs={12} className="fabmaster-con">
            <h3>Fabric Master</h3>
            <div>
              <div
                className="closeRound"
                onClick={() => {
                  dispatch(handleFabricModal());
                  dispatch(setFabricData({}));
                }}
              >
                <CloseIcon />
              </div>
            </div>
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Fabric Name"
              InputLabelProps={{
                shrink:
                  !!getValues("fabricname") ||
                  Object.keys(fabricData).length > 0,
              }}
              inputProps={{
                onInput: (event) => handleMaxLengthInput(event, 150),
              }}
              {...register("fabricname")}
              error={!!errors.fabricname}
              helperText={errors.fabricname?.message}
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              type="submit"
              color="success"
              variant="contained"
              size="small"
              disabled={instanceIsLoading}
            >
              Save
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <TextField
              label="Search"
              size="small"
              variant="standard"
              value={searchStr}
              onChange={(e) => {
                setSearchStr(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setSearchStr("");
                    }}
                  >
                    <CloseIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{ maxHeight: 250, border: "none" }}
              ref={tableRef}
            >
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "600" }}>
                      Fabric Name
                    </TableCell>
                    <TableCell sx={{ fontWeight: "600" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {memoizedRows}
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FabricMaster;
