import React, { useState } from "react";
import AdminLayout from "../Layout/AdminLayout";
import Grid from "@mui/material/Grid";
import NewImagesUpload from "../GlobalComponent/NewImagesUpload";
import { ImageList, Paper, IconButton, ImageListItem, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import "./AdminBanner.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { handleDeleteFiles } from "../../API/Reducer/fileReducer";
import { useBannerConfig } from "../../API/Hooks/useBanner";
import { useGlobalForm } from "../../API/Hooks/useGlobalForm";
import { saveBanner } from "../../API/Reducer/bannerReducer";
import AlertComp from "../AlertMessageCom/AlertComp";
import BannerList from "./BannerList";

const AdminBannerPage = () => {
  const dispatch=useDispatch();
  const { files,previewFiles } = useSelector((state) => state.file)
   
  const { instanceIsLoading } = useSelector((state) => state.menu);

  const { defaultValues, validationSchema } = useBannerConfig();
  const [photos, setPhotos] = useState([]);

  const onSubmit = async (data) => {
    alert()
    const formData = new FormData();
    delete data["imgpath"];
    if (data?.id > 0) {
      formData.append("id", data?.id);
    }
    formData.append("title", data?.title);
    
    if (photos) {      
      if (photos.length === 1) {        
        alert(1)
        formData.append("imgpath", photos[0]);
      } else {        
        for (let i = 0; i < photos.length; i++) {
          alert(2)
          formData.append("imgpath", photos[i]);
        }
      }
    }
    dispatch(saveBanner(formData, reset, setPhotos));
    
  };

  const onError = (errors) => {};

  const { register, handleSubmit, errors, reset, setValue, getValues } =
    useGlobalForm(defaultValues, validationSchema, onSubmit, onError);

  return (
    <>
      <AlertComp/>
      <form onSubmit={handleSubmit} autoComplete="off">
        <AdminLayout>
          <Grid container spacing={1} p={1}>
            <Grid item md={8} xs={12}>
              <Paper elevation={2} className="banrleft">
                <div className="row">
                  <h3>Banner Master</h3>
                  <NewImagesUpload
                    multipleUpload={true}
                    photos={photos}
                    setPhotos={setPhotos}
                  />
                  <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  disabled={instanceIsLoading}>
                  Save
                </Button>
                </div>
                <div>
                  <ImageList
                    sx={{ width: "100%", height: "100%", marginTop: "10px" }}
                    cols={1}
                    rowHeight={164}
                   >
                    {previewFiles.map((img, index) => (
                      <ImageListItem
                        key={index}
                        className="imageListItem"
                      >
                        <img
                          src={img}
                          alt={`uploaded ${index}`}
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                        <IconButton
                          aria-label="delete"
                          className="deleteIcon"
                          onClick={(e)=>{
                             dispatch(handleDeleteFiles(index));   
                          }}                   
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ImageListItem>
                    ))}
                  </ImageList>
                </div>
              </Paper>
            </Grid>
            <Grid item md={4} xs={12}>
              <Paper elevation={2} className="banrright">
                <BannerList/>
              </Paper>
            </Grid>
          </Grid>
        </AdminLayout>
      </form>
    </>
  );
};

export default AdminBannerPage;
