import { configureStore } from "@reduxjs/toolkit";
import confirmDialogReducer from '../Reducer/confirmDialogReducer';
import alertReducer from "../Reducer/alertReducer";
import errorReducer from "../Reducer/errorReducer";
import userReducer from "../Reducer/userReducer";
import menuReducer from "../Reducer/menuReducer";
import adminProductReducer from "../Reducer/adminProductReducer";
import fileReducer from "../Reducer/fileReducer";
import mainProductReducer from "../Reducer/mainProductReducer";
import fabricReducer from "../Reducer/fabricReducer";
import sizeReducer from "../Reducer/sizeReducer";
import subProductReducer from "../Reducer/subProductReducer";
import bannerReducer from "../Reducer/bannerReducer";

const store = configureStore({
    reducer: {
        confirm:confirmDialogReducer,
        alert:alertReducer,
        error:errorReducer,
        user:userReducer,
        menu:menuReducer,
        banner:bannerReducer,
        adminproduct:adminProductReducer,
        mainproduct:mainProductReducer,
        fabric:fabricReducer,
        size:sizeReducer,
        subproduct:subProductReducer,
        file:fileReducer,
        
    },
    
});

export default store;