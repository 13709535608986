import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Paper,
  IconButton,
  InputAdornment,
  Divider,
  Alert,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import getLocalIP from './getLocalIP';

const NotifyLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); // To handle the error
  const [ip, setIP] = useState(null);
  const [loading, setLoading] = useState(true);
    
  const navigate = useNavigate();

  // User Details Array
  const userDetail = [
    { mob: '9822299281', paswd: '9822299281', user: 'Manish',uid:1, },
    { mob: '9423868881', paswd: '9423868881', user: 'Nilesh',uid:2, },
    { mob: '8208045323', paswd: '8208045323', user: 'Rajnikant',uid:3, },
    { mob: '9168848155', paswd: '9168848155', user: 'Prajakta',uid:4, },
    { mob: '7058547174', paswd: '7058547174', user: 'Priyanka',uid:5, },
    { mob: '8805952652', paswd: '8805952652', user: 'Rohan',uid:6, },
  ];

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleLogin = (e) => {
    e.preventDefault();
    const user = userDetail.find(
      (u) => u.mob === mobile && u.paswd === password
    );
    
    if (user) {      
      // Navigate to next page (replace '/dashboard' with your target route)
      navigate('/dashboard', { state: { userName: user.user,uid:user.uid } });
    } else {
      // Show error if credentials don't match
      setError('Invalid mobile number or password!');
    }
  };

  useEffect(() => {
    const fetchLocalIP = async () => {
      try {
        const response = await fetch('http://localhost:8000/api/local-ip/');
        if (!response.ok) {
          throw new Error('Error fetching IP address');
        }
        const data = await response.json();
        setIP(data.ip);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLocalIP();
  }, []);

   

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#f5f5f5', // Clean, neutral background
      }}
    >     
      <Container maxWidth="xs">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              borderRadius: 3,
              backgroundColor: '#fff', // Professional white background
              textAlign: 'center',
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.12)',
            }}
          >
            {/* Company Logo/Brand Name */}
            <Box sx={{ mb: 3 }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: 'bold', color: '#333' }}
              >
                Welcome Back {ip}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: '#777', marginTop: 1 }}
              >
                Log in to your account
              </Typography>
            </Box>

            {/* Error Alert */}
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            {/* Login Form */}
            <Box component="form" onSubmit={handleLogin} noValidate>
              <TextField
                label="Mobile Number"
                variant="outlined"
                fullWidth
                sx={{
                  marginBottom: 2,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ccc', // Neutral border color
                    },
                    '&:hover fieldset': {
                      borderColor: '#1976d2', // Accent color on hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1976d2', // Accent color when focused
                    },
                  },
                  input: {
                    color: '#333',
                  },
                  label: {
                    color: '#555', // Subtle label color
                  },
                }}
                type="tel"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
              <TextField
                label="Password"
                variant="outlined"
                fullWidth
                sx={{
                  marginBottom: 2,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ccc',
                    },
                    '&:hover fieldset': {
                      borderColor: '#1976d2',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1976d2',
                    },
                  },
                  input: {
                    color: '#333',
                  },
                  label: {
                    color: '#555',
                  },
                }}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{ color: '#777' }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <motion.div whileHover={{ scale: 1.02 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: '#1976d2',
                    color: '#fff',
                    fontWeight: 'bold',
                    padding: 1.5,
                    marginTop: 1,
                    '&:hover': {
                      backgroundColor: '#1565c0',
                    },
                  }}
                >
                  Log In
                </Button>
              </motion.div>
            </Box>

            {/* Divider */}
            <Divider sx={{ marginY: 2, borderColor: '#ddd' }} />

            {/* Forgot Password and Sign Up */}
            <Box>
              <Typography
                variant="body2"
                sx={{
                  color: '#555',
                  cursor: 'pointer',
                  '&:hover': {
                    color: '#1976d2',
                  },
                }}
              >
                Forgot your password?
              </Typography>
              <Typography
                variant="body2"
                sx={{ marginTop: 2, color: '#777' }}
              >
                Don't have an account?{' '}
                <span
                  style={{
                    fontWeight: 'bold',
                    color: '#1976d2',
                    cursor: 'pointer',
                  }}
                >
                  Sign Up
                </span>
              </Typography>
            </Box>
          </Paper>
        </motion.div>
      </Container>
    </Box>
  );
};

export default NotifyLogin;
