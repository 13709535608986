import { createSlice } from "@reduxjs/toolkit";
import { setAlertData } from "./alertReducer";
import { apiRequest } from "../../MIS/api";
import { handleInstanceIsLoading } from "./menuReducer";
 
const fabricReducer = createSlice({
  name: "fabric",
  initialState: { 
    fabricData: [],
    fabricList: [],
    fabricPageData: {},
  },

  reducers: {
   
    
    setFabricList(state, action) {
      state.fabricList = action.payload;
    },
    setFabricPageData(state, action) {
      state.fabricPageData = action.payload;
    },   
    setFabricData(state, action) {
      state.fabricData = action.payload;
    },

  },
});

export const {
    setFabricList,
    setFabricPageData,
    setFabricData,   
} = fabricReducer.actions;
export default fabricReducer.reducer;

 

export const saveFabric = (data, reset) => {
  return async function actionData(dispatch, getState) {
    const { fabricData } = getState().fabric;
    dispatch(handleInstanceIsLoading());
    let method="post";
    if(fabricData?.id>0){method="patch"}    
    try {      
      const res = await apiRequest(method, "fabric/", data);
      if (res?.code == 200) {
        if (Object.keys(res?.data).length > 0) {
          dispatch(
            setAlertData({
              type: "success",
              msg: res?.msg,
              code: 200,
            })
          );
          reset();
            dispatch(setFabricData({}))
          await dispatch(getFabricList({ page: 1, limit: 100 }));
        } else {
          dispatch(
            setAlertData({
              type: "error",
              msg: res?.msg,
              code: 100,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setAlertData({
          type: "error",
          msg: "something went wrong! please try again",
          code: 100,
        })
      );
    } finally {
      dispatch(handleInstanceIsLoading());      
    }
  };
};

export const getFabricList = (data) => {  
  const queryParams = new URLSearchParams(data).toString();
  return async function actionData(dispatch, getState) {
    try {      
      const res = await apiRequest("get", `fabric/?${queryParams}`);
      if (res?.code == 200) {
        if (res?.data.length > 0) {
          dispatch(setFabricList(res?.data));
          dispatch(setFabricPageData(res?.meta));                    
        }else{
          dispatch(setFabricList([]));
          dispatch(setFabricPageData([]));      
        }
      }
    } catch (error) {      
      dispatch(
        setAlertData({
          type: "error",
          msg: "something went wrong! please try again",
          code: 100,
        })
      );
    } finally {
    }
  };
};

export const editFabric = (setValue) => {
  return async function actionData(dispatch, getState) {
    const { fabricData } = getState().fabric;
    Object.entries(fabricData).forEach(([key, value]) => {   
        setValue(key,value);      
    });
  }
}

export const deleteFabric = (data) => {
  return async function actionData(dispatch, getState) {
    dispatch(handleInstanceIsLoading());
    try {
      // POST request with custom headers
      const res = await apiRequest("delete", `fabric/`, data);
      if (res?.code == 200) {
        if (res?.data > 0) {
          dispatch(
            setAlertData({
              type: "success",
              msg: res?.msg,
              code: 200,
            })
          );
          await dispatch(getFabricList({ page: 1, limit: 100 }));
        } else {
          dispatch(
            setAlertData({
              type: "error",
              msg: "Delete failed!Please try again",
              code: 100,
            })
          );
        }
      }
    } catch (error) {      
      dispatch(
        setAlertData({
          type: "error",
          msg: "something went wrong! please try again",
          code: 100,
        })
      );
    } finally {
      dispatch(handleInstanceIsLoading());
    }
  };
};


