import { createSlice } from "@reduxjs/toolkit";
import { setAlertData } from "./alertReducer";
import { apiRequest } from "../../MIS/api";
import { handleInstanceIsLoading } from "./menuReducer";
 
const sizeReducer = createSlice({
  name: "size",
  initialState: { 
    sizeData: [],
    sizeList: [],
    sizePageData: {},
  },

  reducers: {
   
    
    setSizeList(state, action) {
      state.sizeList = action.payload;
    },
    setSizePageData(state, action) {
      state.sizePageData = action.payload;
    },   
    setSizeData(state, action) {
      state.sizeData = action.payload;
    },

  },
});

export const {
    setSizeList,
    setSizePageData,
    setSizeData,   
} = sizeReducer.actions;
export default sizeReducer.reducer;

 

export const saveSize = (data, reset) => {
  return async function actionData(dispatch, getState) {
    const { sizeData } = getState().size;
    dispatch(handleInstanceIsLoading());
    let method="post";
    if(sizeData?.id>0){method="patch"}    
    try {      
      const res = await apiRequest(method, "size/", data);
      if (res?.code == 200) {
        if (Object.keys(res?.data).length > 0) {
          dispatch(
            setAlertData({
              type: "success",
              msg: res?.msg,
              code: 200,
            })
          );
          reset();
            dispatch(setSizeData({}))
          await dispatch(getSizeList({ page: 1, limit: 100 }));
        } else {
          dispatch(
            setAlertData({
              type: "error",
              msg: res?.msg,
              code: 100,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setAlertData({
          type: "error",
          msg: "something went wrong! please try again",
          code: 100,
        })
      );
    } finally {
      dispatch(handleInstanceIsLoading());      
    }
  };
};

export const getSizeList = (data) => {  
  const queryParams = new URLSearchParams(data).toString();
  return async function actionData(dispatch, getState) {
    try {      
      const res = await apiRequest("get", `size/?${queryParams}`);
      if (res?.code == 200) {
        if (res?.data.length > 0) {
          dispatch(setSizeList(res?.data));
          dispatch(setSizePageData(res?.meta));                    
        }else{
          dispatch(setSizeList([]));
          dispatch(setSizePageData([]));                    
        } 
      }
    } catch (error) {      
      dispatch(
        setAlertData({
          type: "error",
          msg: "something went wrong! please try again",
          code: 100,
        })
      );
    } finally {
    }
  };
};

export const editSize = (setValue) => {
  return async function actionData(dispatch, getState) {
    const { sizeData } = getState().size;
    Object.entries(sizeData).forEach(([key, value]) => {   
        setValue(key,value);      
    });
  }
}

export const deleteSize = (data) => {
  return async function actionData(dispatch, getState) {
    dispatch(handleInstanceIsLoading());
    try {
      // POST request with custom headers
      const res = await apiRequest("delete", `size/`, data);
      if (res?.code == 200) {
        if (res?.data > 0) {
          dispatch(
            setAlertData({
              type: "success",
              msg: res?.msg,
              code: 200,
            })
          );
          await dispatch(getSizeList({ page: 1, limit: 100 }));
        } else {
          dispatch(
            setAlertData({
              type: "error",
              msg: "Delete failed!Please try again",
              code: 100,
            })
          );
        }
      }
    } catch (error) {      
      dispatch(
        setAlertData({
          type: "error",
          msg: "something went wrong! please try again",
          code: 100,
        })
      );
    } finally {
      dispatch(handleInstanceIsLoading());
    }
  };
};


