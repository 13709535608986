import { createSlice } from "@reduxjs/toolkit"; 
  
const menuReducer = createSlice({
    name: 'menu',
    initialState: {   
        instanceIsLoading:false,        
    },

    reducers: {           
              
       setInstanceIsLoading(state, action) {
        state.instanceIsLoading = action.payload;
       },        
                     
    }
});

export const {setInstanceIsLoading} = menuReducer.actions;
export default menuReducer.reducer;
 
export const handleInstanceIsLoading = () => {
    return function actionData(dispatch,getState) {
      const {instanceIsLoading}=getState().menu;
      dispatch(setInstanceIsLoading(!instanceIsLoading));
    };
  };