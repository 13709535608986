import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSelector, useDispatch } from 'react-redux';
import { alertClose } from "../../API/Reducer/alertReducer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertComp() {
    const dispatch = useDispatch();
    const {alertData} = useSelector((state) => state.alert);
    
    
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={alertData?.open}
        autoHideDuration={alertData?.duration}
        onClose={()=>{dispatch(alertClose(false))}}
        anchorOrigin={{
          vertical: `${alertData?.vertical}`,
          horizontal: `${alertData?.horizontal}`,
        }}
      >
        {alertData?.code == 200 ? (
          <Alert
            onClose={()=>{dispatch(alertClose(false))}}
            severity="success"
            sx={{ width: "100%" }}
          >
            {alertData?.msg}
          </Alert>
        ) : alertData?.code == 300 ? (
          <Alert
            onClose={()=>{dispatch(alertClose(false))}}
            severity="warning"
            sx={{ width: "100%" }}
          >
            {alertData?.msg}
          </Alert>
        ) : alertData?.code == 400 ? (
          <Alert onClose={()=>{dispatch(alertClose(false))}} severity="info" sx={{ width: "100%" }}>
          {alertData?.msg}
          </Alert>
        ) : (
          <Alert onClose={()=>{dispatch(alertClose(false))}} severity="error" sx={{ width: "100%" }}>
          {alertData?.msg}
          </Alert>
        )}
      </Snackbar>
    </Stack>
  );
}
