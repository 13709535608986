import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./adminproduct.scss";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField, CircularProgress } from "@mui/material";
import noimage from "../../Assets/noimage.png";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMainProduct,
  getMainProductList,
  setMainProductData,
} from "../../API/Reducer/adminProductReducer";
import { getImageUrl } from "../../MIS/Global";
import CloseIcon from '@mui/icons-material/Close';

const MainProductList = () => {
  const [searchStr, setSearchStr] = useState("");
  const [orgdata, setOrgData] = useState([]);
  const [loading, setLoading] = useState(false);
  const observerRef = useRef(null);
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { mainProductList, mainProductPageData } = useSelector(
    (state) => state.adminproduct
  );

  const loadInitialData = async (data={ page: 1, limit: 100 }) => {
    setLoading(true);
    await dispatch(getMainProductList(data));
    setLoading(false);
  };
  useEffect(() => {
    loadInitialData();
  }, [dispatch]);

  const loadMoreData = useCallback(async () => {
    if (!loading && mainProductPageData?.currentPage < mainProductPageData?.totalPages) {
      setLoading(true);
      const newPage = mainProductPageData?.currentPage + 1;
      await dispatch(getMainProductList({ page: newPage, limit: 100 }));
      setLoading(false);
    }
  }, [mainProductPageData?.currentPage, mainProductPageData?.totalPages, loading, dispatch]);

  const observeBottom = useCallback(
    (entries) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting) {
        loadMoreData(); // Trigger loading more data when the bottom is reached
      }
    },
    [loadMoreData]
  );

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect(); // Disconnect any existing observer

    if (tableRef.current) {
      const bottomElement = tableRef.current.querySelector("li:last-child"); // Get the bottom row
      if (bottomElement) {
        observerRef.current = new IntersectionObserver(observeBottom);
        observerRef.current.observe(bottomElement); // Observe bottom row
      }
    }

    return () => {
      if (observerRef.current) observerRef.current.disconnect(); // Cleanup observer on unmount
    };
  }, [orgdata, observeBottom]);

  useEffect(() => {
    if(mainProductList?.length>0){
      setOrgData(mainProductList);
    }
  }, [mainProductList]);
 
  const searchInArray = (key, searchValue=searchStr, array = mainProductList) => {
    const filteredData = array.filter((item) => {
      if (Array.isArray(key)) {
        // For multiple keys
        return key.some((k) =>
          item[k]?.toString().toLowerCase().includes(searchValue.toLowerCase())
        );
      } else {
        // For a single key
        return item[key]?.toString().toLowerCase().includes(searchValue.toLowerCase());
      }
    });
    setOrgData(filteredData);
    if(filteredData.length<=0){
      loadInitialData({ page: 1, limit: 100,mpname:searchStr});
    }
  };
  useEffect(() => {
    searchInArray("mpname")
  }, [searchStr]);

  const memoizedRows = useMemo(() => {
    return orgdata.map((element, index) => {
      let bgcolor = "#fff";
      if (index % 2 === 0) {
        bgcolor = "aliceblue";
      }
      return (
        <li
          className="mainprod-card"
          key={index}
          style={{ backgroundColor: bgcolor, cursor: "pointer" }}
          onClick={(e) => {
            dispatch(setMainProductData(element));
          }}
        >
          <div className="row">
            <div className="imgcon">
              <img src={getImageUrl(`${element?.imgpath}`) || noimage} alt="product" />
            </div>
            <h5>{element?.mpname}</h5>
          </div>
          <div
            className="del"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(deleteMainProduct(element));
            }}
          >
            <DeleteIcon sx={{ fontSize: "14px" }} />
          </div>
        </li>
      );
    });
  }, [orgdata, dispatch]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            maxHeight: "10vh",
            position: "sticky",
          }}
        >
          <TextField
            label="Search"
            size="small"
            variant="standard"
            value={searchStr}
            onChange={(e) => {
              setSearchStr(e.target.value);
              // searchInArray("mpname", e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start" sx={{ cursor: "pointer" }}
                 onClick={(e)=>{setSearchStr("")}}
                >
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={12} xs={12} sx={{ maxHeight: "90vh", overflowY: "scroll" }}>
          <ul ref={tableRef}>
            {memoizedRows}
          </ul>
          {loading && (
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <CircularProgress />
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default MainProductList;
