import { useMemo } from "react";
import { handleUserGetLocalStorage } from "../../MIS/Global";
 
// Hook for global form configuration
export const useFormConfig = (customValues = {}, customValidation = {}) => {
  // Default values (with possibility of dynamic or passed values)
  const defaultValues = useMemo(() => ({
    id: null,
    mpname: "",
    imgpath: "",
    loginid: handleUserGetLocalStorage("loginid"),
    ...customValues, // Allows custom default values passed from the component
  }), [customValues]);

  // Validation schema
  const validationSchema = useMemo(() => ({
    mpname: {
      required: "Product name is required",
      minLength: {
        value: 3,
        message: "Product Name must be at least 3 characters long",
      },
      ...customValidation.mpname, // Override validation if provided
    },
    // You can add more fields here
  }), [customValidation]);

  return { defaultValues, validationSchema };
};
