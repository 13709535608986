import { Box, Button, Grid, IconButton, ImageList, ImageListItem, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useGlobalForm } from '../../API/Hooks/useGlobalForm';
import { getMainProductList_Option } from '../../API/Reducer/adminProductReducer';
import NewImagesUpload from '../GlobalComponent/NewImagesUpload';
import CustomSelect from '../GlobalComponent/CustomSelect';
import { useDispatch, useSelector } from 'react-redux';
import { handleDeleteFiles } from '../../API/Reducer/fileReducer';
import DeleteIcon from "@mui/icons-material/Delete";
import { getSubProductList_Option } from '../../API/Reducer/subProductReducer';

const Product = () => {
    const dispatch = useDispatch();
    const [selectedProduct, setSelectedProduct] = useState({});
    const [selectedSubProduct, setSelectedSubProduct] = useState({});
    const [photos, setPhotos] = useState([]);

  const { files, previewFiles } = useSelector((state) => state.file);
  const { instanceIsLoading } = useSelector((state) => state.menu);  
  const { mainProductOption } = useSelector((state) => state.adminproduct);
  const { subProductOption } = useSelector((state) => state.subproduct);

  const onSubmit = async (data) => {    
    if (selectedProduct?.label == "" || selectedProduct?.label == undefined) {
       setSelectedProduct({ ...selectedProduct, isselect: false });
      return;
    }    
   
  };

  const onError = (errors) => {};
  const { register, handleSubmit, errors, reset, setValue, getValues } =
    useGlobalForm({}, {}, onSubmit, onError);

    useEffect(() => {
        dispatch(getMainProductList_Option({page:1,limit:1000}))
        dispatch(getSubProductList_Option({page:1,limit:1000}))
      },[])

  return (
    <>
    <form  autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Box>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  disabled={instanceIsLoading}
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>                
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <NewImagesUpload
                      multipleUpload={true}
                      photos={photos}
                      setPhotos={setPhotos}
                    />
                  </Box>
                
              </Grid>
              <Grid item xs={12} md={8}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={7}>
                      <CustomSelect
                        options={mainProductOption}
                        selectText={selectedProduct?.label || "Select a Main-Product"}
                        setSelectedValue={setSelectedProduct}
                      />
                      {selectedProduct?.isselect === false && (
                        <Typography color="error" variant="subtitle2">
                          Main-Product Name is required
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={7} xs={12}>
                     <CustomSelect
                        options={subProductOption}
                        selectText={selectedSubProduct?.label || "Select a Sub-Product"}
                        setSelectedValue={setSelectedSubProduct}
                      />
                      {selectedSubProduct?.isselect === false && (
                        <Typography color="error" variant="subtitle2">
                          Sub-Product Name is required
                        </Typography>
                      )}
                    </Grid>
                    
                  </Grid>
                </Box>
              </Grid>
              
              <Grid item md={12} xs={12}>
                <Paper sx={{ padding: "10px" }} elevation={3}>
                <ImageList
                    sx={{ width: "100%", height: "100%", marginTop: "10px" }}
                    cols={3}
                    rowHeight={164}
                   >
                    {previewFiles.map((img, index) => (
                      <ImageListItem
                        key={index}
                        className="imageListItem"
                      >
                        <img
                          src={img}
                          alt={`uploaded ${index}`}
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                        <IconButton
                          aria-label="delete"
                          className="deleteIcon"
                          onClick={(e)=>{
                             dispatch(handleDeleteFiles(index));   
                          }}                   
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Paper>
              </Grid>
               
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ padding: "10px" }}>
            
          </Paper>
        </Grid>
      </Grid>
    </form>
    </>
  )
}

export default Product