import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "../../Components/AdminProduct/adminproduct.scss";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField, CircularProgress, IconButton, Box } from "@mui/material";
import noimage from "../../Assets/noimage.png";
import { useDispatch, useSelector } from "react-redux"; 
import { getImageUrl } from "../../MIS/Global";
import CloseIcon from '@mui/icons-material/Close';
import { deleteBanner, getBannerList } from "../../API/Reducer/bannerReducer";

const BannerList = () => {
  const [searchStr, setSearchStr] = useState("");
  const [orgdata, setOrgData] = useState([]);
  const [loading, setLoading] = useState(false);
  const observerRef = useRef(null);
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  
  const { bannerList, bannerPageData } = useSelector(
    (state) => state.banner
  );

  const loadInitialData = async (data={ page: 1, limit: 100 }) => {
    setLoading(true);
    await dispatch(getBannerList(data));
    setLoading(false);
  };
  useEffect(() => {
    loadInitialData();
  }, [dispatch]);

  const loadMoreData = useCallback(async () => {
    if (!loading && bannerPageData?.currentPage < bannerPageData?.totalPages) {
      setLoading(true);
      const newPage = bannerPageData?.currentPage + 1;
      await dispatch(getBannerList({ page: newPage, limit: 100 }));
      setLoading(false);
    }
  }, [bannerPageData?.currentPage, bannerPageData?.totalPages, loading, dispatch]);

  const observeBottom = useCallback(
    (entries) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting) {
        loadMoreData(); // Trigger loading more data when the bottom is reached
      }
    },
    [loadMoreData]
  );

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect(); // Disconnect any existing observer

    if (tableRef.current) {
      const bottomElement = tableRef.current.querySelector("li:last-child"); // Get the bottom row
      if (bottomElement) {
        observerRef.current = new IntersectionObserver(observeBottom);
        observerRef.current.observe(bottomElement); // Observe bottom row
      }
    }

    return () => {
      if (observerRef.current) observerRef.current.disconnect(); // Cleanup observer on unmount
    };
  }, [orgdata, observeBottom]);

  useEffect(() => {
    if(bannerList?.length>0){
      setOrgData(bannerList);
    }
  }, [bannerList]);
 
   

  const memoizedRows = useMemo(() => {
    return orgdata.map((element, index) => {
      let bgcolor = "#fff";
      if (index % 2 === 0) {
        bgcolor = "aliceblue";
      }
      return (
        <li
          className="mainprod-card"
          key={index}
          style={{ backgroundColor: bgcolor, cursor: "pointer" }}          
        >
          <div className="row">
            <div className="imgcon" style={{width:"100%"}}>
              <img src={getImageUrl(`${element?.imgpath}`) || noimage} alt="product" />
            </div>           
          </div>
          <div
            className="del"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(deleteBanner(element));
            }}
          >
            <DeleteIcon sx={{ fontSize: "14px" }} />
          </div>
        </li>
      );
    });
  }, [orgdata, dispatch]);

  return (
    <>
      <Grid container spacing={1}>        
        <Grid item md={12} xs={12} sx={{ maxHeight: "90vh", overflowY: "scroll" }}>
          <ul ref={tableRef}>
            {memoizedRows}
          </ul>
          {loading && (
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <CircularProgress />
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default BannerList;
