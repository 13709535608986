import { useMemo } from "react";
import { handleUserGetLocalStorage } from "../../MIS/Global";
 
// Hook for global form configuration
export const useFabricConfig = (customValues = {}, customValidation = {}) => {
  // Default values (with possibility of dynamic or passed values)
  const defaultValues = useMemo(() => ({
    id: null,
    fabricname: "",
    fabric_price: "0.00",
    fabric_status:"0",
    loginid: handleUserGetLocalStorage("loginid"),
    ...customValues,
  }), [customValues]);

  // Validation schema
  const validationSchema = useMemo(() => ({
    fabricname: {
      required: "Fabric name is required",
      minLength: {
        value: 3,
        message: "Fabric Name must be at least 3 characters long",
      },
     
    },
    // You can add more fields here
  }), [customValidation]);

  return { defaultValues, validationSchema };
};
