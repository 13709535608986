import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFabricList } from "../../API/Reducer/fabricReducer";
import { toggleFabricCheckList } from "../../API/Reducer/adminProductReducer";

const FabricList = ({searchFabricStr}) => {
  const [orgData, setOrgData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const { fabricList, fabricPageData, fabricData } = useSelector(
    (state) => state.fabric
  );
  const { fabricCheckData } = useSelector((state) => state.adminproduct);

  const loadInitialData = async (data = { page: 1, limit: 100 }) => {
    setLoading(true);
    await dispatch(getFabricList(data));
    setLoading(false);
  };
  useEffect(() => {
    loadInitialData();
  }, [dispatch]);

  useEffect(() => {
    if (fabricList?.length > 0) {
      setOrgData(fabricList);
    }
  }, [fabricList]);

  const searchInArray = (key, searchValue = searchFabricStr, array = fabricList) => {
    const filteredData = array.filter((item) => {
      if (Array.isArray(key)) {        
        return key.some((k) =>
          item[k]?.toString().toLowerCase().includes(searchValue.toLowerCase())
        );
      } else {        
        return item[key]
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      }
    });
    setOrgData(filteredData);
    if (filteredData.length <= 0) {
      loadInitialData({ page: 1, limit: 100, fabricname: searchFabricStr });
    }
  };
  useEffect(() => {
    searchInArray("fabricname");
  }, [searchFabricStr]);

  const handleCheckboxChange = (event, fabricId) => {
    dispatch(toggleFabricCheckList(fabricId));
  };
  return (
    <>
      <ul className="horizontal-list">
        {orgData.map((element, index) => {
          return (
            <li key={index} style={{width:"150px",whiteSpace: "normal", wordWrap: "break-word"}}>
              <FormControlLabel
                control={<Checkbox name="gilad" color="success"
                onChange={(event) => handleCheckboxChange(event, element?.id)}
                checked={fabricCheckData.includes(element?.id)}
                />}
                label={element?.fabricname}                
              />
            </li>
          );
        })}         
      </ul>
    </>
  );
};

export default FabricList;
