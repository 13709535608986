import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  MenuItem,
  Select,
  TextField,
  Paper,
  Divider,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiRequest } from '../../MIS/api';

const NotificationDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);  
  const [message, setMessage] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state for the button

  const userName = location?.state?.userName || 'Guest';
  const uid = location?.state?.uid || 0;

  const userList = [
    { mob: '9822299281', user: 'Manish', uid: 1 },
    { mob: '9423868881', user: 'Nilesh', uid: 2 },
    { mob: '8208045323', user: 'Rajnikant', uid: 3 },
    { mob: '9168848155', user: 'Prajakta', uid: 4 },
    { mob: '7058547174', user: 'Priyanka', uid: 5 },
    { mob: '8805952652', user: 'Rohan', uid: 6 },
  ];

  // Handle user selection from the dropdown
  const handleUserChange = (event) => {
    const selectedUid = event.target.value;
    const selectedUserDetails = userList.find((user) => user.uid === selectedUid);    
    
    setSelectedUser(selectedUserDetails);  // Update state with the selected user's details
  };

  const handleSendNotification = async () => {
    if (selectedUser && message) {
      setIsLoading(true); // Disable button
      let data = {
        title: `Title-${selectedUser.uid}`,
        redirect_url: '-',
        uid: selectedUser.uid,  // Use the selected user's uid
        is_status: 0,
        msg: message,
      };
      const res = await apiRequest('post', 'notification/', data);

      if (res?.code === 200) {
        alert('The notification has been sent successfully.');
        setMessage(''); // Clear the message text area
      } else {
        alert('Something went wrong! Please try again.');
      }

      setIsLoading(false); // Enable button after request completes
    } else {
      alert('Please select a user and enter a message.');
    }
  };

  const handleLogout = () => {
    navigate('/india');
  };

  useEffect(() => {
    const initialData = async () => {
      let data = { page: 1, limit: 100, uid: uid };
      const queryParams = new URLSearchParams(data).toString();
      try {
        const res = await apiRequest('get', `notification/?${queryParams}`);
        if (res?.code === 200) {
          if (res?.data.length > 0) {
            setNotifications(res?.data);
          } else {
            setNotifications([]); // No data, set to empty array
          }
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setNotifications([]); // Optionally handle errors by resetting notifications
      }
    };

    initialData();
  }, []);

  useEffect(() => {      
    if (!uid) return;
    // Create the WebSocket connection
    const socket = new WebSocket(`ws://127.0.0.1:8000/ws/notify/${uid}/`);
    // const socket = new WebSocket(`ws://103.86.177.200/ws/notify/${uid}/`);
    
    
  
    // Triggered when a message is received from the server
    socket.onmessage = (event) => { 
      try {
        const data = JSON.parse(event.data);  // Parse the JSON data received        
        // Check if data is an object or an array
        if (Array.isArray(data)) {
          // If the data is an array, prepend it at the beginning of the existing notifications
          setNotifications((prevNotifications) => [...data, ...prevNotifications]);
        } else {
          // If the data is an object, prepend it as a single item
          setNotifications((prevNotifications) => [data, ...prevNotifications]);
        }
      } catch (err) {
        console.error("Failed to parse WebSocket message", err);
      }
    };
  
    // Error handling for WebSocket
    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      socket.close();  // Close the connection on error
    };
  
    // Cleanup function to close the WebSocket connection when the component unmounts
    return () => {
      socket.close();
    };
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        mt: 4,
      }}
    >
      {/* Left Section */}
      <Box sx={{ width: '40%' }}>
        <Paper elevation={3} sx={{ p: 3, borderRadius: 3 }}>
          {/* Welcome and Logout */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              Welcome, {userName}
            </Typography>
            <Button
              variant="outlined"
              color="error"
              onClick={handleLogout}
              sx={{ mb: 2 }}
            >
              Logout
            </Button>
          </Box>

          <Divider sx={{ mb: 3 }} />

          {/* Select User */}
          <Typography variant="h6" sx={{ mb: 2 }}>
            Select User
          </Typography>
          <Select
            value={selectedUser?.uid || ''}  // Ensure value corresponds to the selected uid
            onChange={handleUserChange}
            fullWidth
            variant="outlined"
            sx={{ mb: 3 }}
          >
            {userList
              .filter((user) => user.uid !== uid)  // Skip the logged-in user
              .map((user) => (
                <MenuItem key={user.uid} value={user.uid}>
                  {user.user}
                </MenuItem>
              ))}
          </Select>

          {/* Message Text Area */}
          <Typography variant="h6" sx={{ mb: 2 }}>
            Message/Action
          </Typography>
          <TextField
            label="Enter your message"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{ mb: 3 }}
          />

          {/* Send Notification Button */}
          <motion.div whileHover={{ scale: isLoading ? 1 : 1.02 }}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSendNotification}
              disabled={isLoading} // Disable button while loading
              sx={{
                backgroundColor: isLoading ? '#ccc' : '#1976d2',
                color: '#fff',
                fontWeight: 'bold',
                padding: 1.5,
                '&:hover': {
                  backgroundColor: isLoading ? '#ccc' : '#1565c0',
                },
              }}
            >
              {isLoading ? 'Sending...' : 'Send Notification'}
            </Button>
          </motion.div>
        </Paper>
      </Box>

      {/* Right Section (Notification Box) */}
      <Box sx={{ width: '55%' }}>
        <Paper
          elevation={3}
          sx={{
            p: 3,
            minHeight: '400px',
            borderRadius: 3,
            backgroundColor: '#f5f5f5',
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
            Notifications
          </Typography>
          <Box
            sx={{
              maxHeight: '350px',
              overflowY: 'auto',
              backgroundColor: '#fff',
              p: 2,
              borderRadius: 2,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            {notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <Paper
                  key={index}
                  sx={{
                    p: 2,
                    mb: 2,
                    backgroundColor: '#e3f2fd',
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {notification?.msg}
                  </Typography>
                  {/* <Typography variant="body1">{notification?.title}</Typography> */}
                </Paper>
              ))
            ) : (
              <Typography>No notifications available.</Typography>
            )}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default NotificationDashboard;
