import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import axios from "axios";

// Memoized TableRow Component with a custom comparison to avoid re-renders
const TableRow = React.memo(({ item, groupIndex }) => {
  return (
    <tr>
      <td>{groupIndex}</td>
      <td>{item.title}</td>
      <td>{item.image_url}</td>
    </tr>
  );
}, (prevProps, nextProps) => {
  return prevProps.item === nextProps.item && prevProps.groupIndex === nextProps.groupIndex;
});

const PdfGeneratorWithApi = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [fetching, setFetching] = useState(false);
  const pageSize = 5;
  const loadMoreRef = useRef(null);

  const observerOptions = useMemo(() => ({
    root: null,
    rootMargin: "200px",
    threshold: 0.5,
  }), []);

  // IntersectionObserver to handle infinite scrolling
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && hasMoreData && !fetching) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      },
      observerOptions
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasMoreData, fetching, observerOptions]);

  // Fetch data when currentPage changes
  useEffect(() => {
    if (hasMoreData && !fetching) {
      setIsLoading(true);
      fetchParallelData(currentPage).finally(() => setIsLoading(false));
    }
  }, [currentPage, hasMoreData]);

  const fetchParallelData = async (startingPage) => {
    if (fetching) return;
    setFetching(true);

    const promises = [];
    const pageToFetch = startingPage;

    if (pageToFetch > Math.ceil(totalRecords / pageSize) && totalRecords > 0) {
      setHasMoreData(false);
    } else {
      promises.push(fetchData(pageToFetch));
    }

    try {
      const results = await Promise.all(promises);
      const fetchedData = results.flat();

      // Efficiently updating state without causing unnecessary re-renders
      setData((prevData) => [...prevData, ...fetchedData]);

      if (fetchedData.length < pageSize) {
        setHasMoreData(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setFetching(false);
  };

  const fetchData = async (page) => {
    try {
      const response = await axios.get(
        `http://127.0.0.1:8000/api/banners/?action_type=get&page=${page}&limit=${pageSize}`
      );
      setTotalRecords(response.data.total);
      return response.data.items;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  // Memoizing the rows to prevent unnecessary re-renders
  const memoizedRows = useMemo(() => {
    let groupIndex = 1;
    return data.map((item, index) => {
      const previousItem = data[index - 1];
      const isNewGroup = !previousItem || previousItem.title !== item.title;

      if (isNewGroup) {
        groupIndex = 1;
      }

      return (
        <React.Fragment key={item.id || index}>
          {isNewGroup && (
            <>
              <tr style={groupRowStyle}>
                <th colSpan={3} style={groupHeaderStyle}>
                  Group By {item.title}
                </th>
              </tr>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Status</th>
              </tr>
            </>
          )}

          <TableRow item={item} groupIndex={groupIndex++} />
        </React.Fragment>
      );
    });
  }, [data]);

  const generatePDF = useCallback(() => {
    // PDF generation logic using worker...
  }, [data]);

  return (
    <div>
      <button onClick={generatePDF}>Generate PDF</button>
      {isLoading || fetching ? (
        <p>Loading data...</p>
      ) : (
        <table border="1" width="100%">
          <tbody>{memoizedRows}</tbody>
        </table>
      )}
      <div ref={loadMoreRef} style={{ height: "20px" }}></div>
    </div>
  );
};

const groupRowStyle = { textAlign: "center", border: "none" };
const groupHeaderStyle = { textAlign: "center", border: "none" };

export default PdfGeneratorWithApi;
