import { createSlice } from "@reduxjs/toolkit"; 
import { setConfirmDialogData } from "./confirmDialogReducer";
import { reloadPage, setAlertData } from "./alertReducer";
import { errorClear, errorSet } from "./errorReducer";
   

const mainProductReducer = createSlice({
    name: 'mainproduct',
    initialState: {        
        mainProductData:[],     
    },

    reducers: {                 
        setMainProductData(state,action){
          state.mainProductData.push(action.payload);
        },
                     
    }
});

export const {setMainProductData} = mainProductReducer.actions;
export default mainProductReducer.reducer;
 
export const handleUserChange = (e, name) => {
    return function actionData(dispatch) {
      if (e && e.target) {
        // dispatch(setUser({ name: e.target.name, value: e.target.value }));
      }
    };
  };

export const saveMainProduct = () => {

}



