import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,  
  InputAdornment,
  Modal,
  Paper,
  TextField,
  CircularProgress,
} from "@mui/material";
import "./adminproduct.scss";
import noimage from "../../Assets/noimage.png";
import {
  getImageUrl,
  handleMaxLengthInput,
  handleUserGetLocalStorage,
} from "../../MIS/Global";
import AlertComp from "../AlertMessageCom/AlertComp";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import FabricList from "./FabricList";
import SizeList from "./SizeList";
import { useDispatch, useSelector } from "react-redux";
import {
  editMainProduct,
  handleFabricModal,
  handleSizeModal,
  saveMainProduct,
} from "../../API/Reducer/adminProductReducer";
import FabricMaster from "./FabricMaster";
import SizeMaster from "./SizeMaster";
import MainProductList from "./MainProductList";
import { useGlobalForm } from "../../API/Hooks/useGlobalForm"; // Custom global form hook
import { useFormConfig } from "../../API/Hooks/useMainProduct"; // Custom global form hook
import NewImagesUpload from "../GlobalComponent/NewImagesUpload";
import { handleInstanceIsLoading } from "../../API/Reducer/menuReducer";
import { setFabricData } from "../../API/Reducer/fabricReducer";
import { setSizeData } from "../../API/Reducer/sizeReducer";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  p: 1,
};
const MainProduct = () => {
  const [searchSizeStr, setSearchSizeStr] = useState("");
  const [searchFabricStr, setSearchFabricStr] = useState("");

  const dispatch = useDispatch();
  const { fabricModal, sizeModal, mainProductData } = useSelector(
    (state) => state.adminproduct
  );
  const { files, previewFiles } = useSelector((state) => state.file);
  const { instanceIsLoading } = useSelector((state) => state.menu);

  const { defaultValues, validationSchema } = useFormConfig();
  const [photos, setPhotos] = useState([]);
  const [editImgUrl, setEditImgUrl] = useState("");

  const onSubmit = async (data) => {
    const formData = new FormData();
    delete data["imgpath"];
    if (data?.id > 0) {
      formData.append("id", data?.id);
    }
    formData.append("mpname", data?.mpname);
    formData.append("loginid", data?.loginid);
    if (photos) {
      formData.append("imgpath", photos?.[0]);
    }
    dispatch(handleInstanceIsLoading());
    dispatch(saveMainProduct(formData, reset, setPhotos));
  };

  const onError = (errors) => {};

  const { register, handleSubmit, errors, reset, setValue, getValues } =
    useGlobalForm(defaultValues, validationSchema, onSubmit, onError);

  useEffect(() => {
    if (Object.keys(mainProductData).length > 0) {
      dispatch(editMainProduct(setValue));
      setEditImgUrl(getImageUrl(`${mainProductData?.imgpath}`));
    }
  }, [mainProductData]);

  return (
    <>
      {/* <AlertComp /> */}
      <form onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item md={9} xs={12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  disabled={instanceIsLoading}
                >
                  Save
                </Button>
              </Grid>
              <Grid md={4} xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                    margin: "10px",
                  }}
                >
                  <div>
                    {previewFiles.length > 0 ? (
                      previewFiles.map((preview, index) => (
                        <img
                          key={index} // Add key for each item in the map
                          src={preview}
                          alt={`uploaded preview ${index}`}
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "250px",
                          }}
                        />
                      ))
                    ) : (
                      <img
                        src={
                          Object.keys(mainProductData).length > 0
                            ? editImgUrl
                            : noimage
                        }
                        alt="No Image"
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "250px",
                        }}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <NewImagesUpload
                      multipleUpload={false}
                      photos={photos}
                      setPhotos={setPhotos}
                    />
                  </div>
                </div>
              </Grid>
              <Grid md={4} xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  label="Main Product Name"
                  InputLabelProps={{
                    shrink:
                      !!getValues("mpname") ||
                      Object.keys(mainProductData).length > 0,
                  }}
                  inputProps={{
                    onInput: (event) => handleMaxLengthInput(event, 150),
                  }}
                  {...register("mpname")}
                  error={!!errors.mpname}
                  helperText={errors.mpname?.message}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Paper sx={{ padding: "10px" }} elevation={3}>
                  <div className="mpfabric">
                    <div className="row1">
                      <h3>Add Fabric & Size</h3>
                      <div className="row2">      
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          type="button"
                          onClick={() => {
                            dispatch(handleFabricModal());
                          }}
                        >
                          <AddIcon /> Fabric
                        </Button>
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          type="button"
                          onClick={() => {
                            dispatch(handleSizeModal());
                          }}
                        >
                          <AddIcon /> Size
                        </Button>
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <hr className="styled-hr"></hr>
                    </div>
                
                  </div>
                </Paper>
              </Grid>
             
            </Grid>
          </Grid>
          <Grid item md={3} xs={12}>
            <Paper elevation={3} sx={{ padding: "10px" }}>
              <MainProductList />
            </Paper>
          </Grid>
        </Grid>
      </form>

      <Modal
        open={fabricModal}
        onClose={() => {
          dispatch(handleFabricModal());
          dispatch(setFabricData({}));
        }}
      >
        <Box sx={style}>
          <FabricMaster />
        </Box>
      </Modal>
      <Modal
        open={sizeModal}
        onClose={() => {
          dispatch(handleSizeModal());
          dispatch(setSizeData({}));
        }}
      >
        <Box sx={style}>
          <SizeMaster />
        </Box>
      </Modal>
    </>
  );
};

export default MainProduct;
