import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Table, TableRow, TableCell, TableHead, TableBody } from '@mui/material'; // Material-UI Table
import axios from 'axios';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import logo from '../../Assets/jc_logo.png';

const doc = new jsPDF()
// Simulate data fetching with pagination
const fetchData = async (page, limit) => {
  const response = await axios.get(`http://127.0.0.1:8000/api/banners/?action_type=get&page=${page}&limit=${limit}`);
  return response.data; // Return the entire response (not just items)
};

const LargeDataTable = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const observerRef = useRef(null);
  const tableRef = useRef(null);

  // Fetch initial data
  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      const responseData = await fetchData(1, 100); // Fetch the first 50 records
      setData(responseData.items);
      setTotalPages(responseData.total_pages); // Set total pages from the response
      setLoading(false);
    };
    loadInitialData();
  }, []);

  // Function to fetch more data on scroll
  const loadMoreData = useCallback(async () => {
    if (!loading && page < totalPages) {
      setLoading(true);
      const newPage = page + 1;
      const responseData = await fetchData(newPage, 100); // Fetch 50 records at a time
      setData((prevData) => [...prevData, ...responseData.items]); // Append new data
      setPage(newPage);
      setLoading(false);
    }
  }, [page, totalPages, loading]);

  // IntersectionObserver callback to detect when scrolling near the bottom
  const observeBottom = useCallback((entries) => {
    const firstEntry = entries[0];
    if (firstEntry.isIntersecting) {
      loadMoreData(); // Trigger loading more data when bottom is reached
    }
  }, [loadMoreData]);

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect(); // Disconnect any existing observer
    observerRef.current = new IntersectionObserver(observeBottom);

    const bottomElement = tableRef.current.querySelector('tbody > tr:last-child'); // Get the bottom row
    if (bottomElement) observerRef.current.observe(bottomElement); // Observe bottom row
    return () => observerRef.current.disconnect(); // Cleanup observer on unmount
  }, [data, observeBottom]);

  // Memoize the rows to avoid unnecessary re-renders
  const memoizedRows = useMemo(() => {
    return data.map((record, index) => (
      <TableRow key={record.id}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{record.title}</TableCell>
        <TableCell>{record.image_url}</TableCell>
      </TableRow>
    ));
  }, [data]);
  const generatePDF = async() => {

    // if (page < totalPages) {
    //     setLoading(true); // Show loading indicator
    
    //     // Fetch all remaining data asynchronously
    //     for (let newPage = page + 1; newPage <= totalPages; newPage++) {
    //       const responseData = await fetchData(newPage, 100); // Fetch 50 records at a time
    //       setData((prevData) => [...prevData, ...responseData.items]); // Append new data to table
    //       setPage(newPage); // Update the current page
    //     }
        
    //     setLoading(false); // Hide loading indicator when data is fully loaded
    //   }
    

        // let allData = [...data];
        // let currentPage = page;
        // let totPages=totalPages;
        // setLoading(true);

        // while (currentPage < totPages) {  
        // currentPage++;      
        // const responseData = await fetchData(currentPage, 1000);
        // allData = [...allData, ...responseData.items];
        // // setData((prevData) => [...prevData, ...responseData.items]); // Append new data to table
        // // setPage(currentPage);
        // totPages=responseData.total_pages;
        // }

        // setLoading(false);

        //   alert()

    const doc = new jsPDF();
    let isFirstPage = true;
    let totalPagesCount = 0; // Initialize total page count
    let pageCount = 0;

    doc.autoTable({
      html: '#mytable', // Use the table directly
      theme: 'grid',
      startY: 20,
      headStyles: { fillColor: [22, 160, 133] },
      didDrawCell: (data) => {
        // If this is the first page and the cell is in the header
        if (isFirstPage && data.cell.section === 'head') {
          // Hide the cell by setting its width to 0
          doc.setLineWidth(0); // Remove borders
          doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F'); // Fill with white
        }
      },
      didDrawPage: (data) => {
        // Check if it's the first page
        if (isFirstPage) {
          // After drawing the first page, reset the flag
          isFirstPage = false;
        }                 
    },
    });
    totalPagesCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPagesCount; i++) {
        doc.setPage(i);
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
    
        // Update page number footer for each page
        doc.setFontSize(10);
        doc.text(`Page ${i} of ${totalPagesCount}`, pageWidth - 30, pageHeight - 10);
      }

    doc.save('table.pdf');
  }
  return (
    <>
    <button onClick={generatePDF}>Generate PDF</button>
    <div ref={tableRef}>
      <Table id="mytable">
        <TableHead sx={{display:"none"}}>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Image URL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {memoizedRows}
        </TableBody>
      </Table>
      {loading && <p>Loading more data...</p>}
    </div>
    </>
  );
};

export default LargeDataTable;
